
import Button from '~/components/ui/Button.vue'

export default {
  components: { Button },
  props: {
    buttonProps: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: '',
    },
    dropdownImg: {
      type: String,
      default: '',
    },
  },
}
