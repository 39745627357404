const resource = '/user'

export default $axios => ({
	async createWishList({ id, ...data }) {
		return await $axios({
			method: 'PUT',
			url: `${resource}/${id}/wishlist`,
			data
		})
	},
	async removeList(data) {
		return await $axios({
			method: 'DELETE',
			url: `${resource}/${data.userId}/wishlist/${data.listId}`
		})
	},
	async renameList(data) {
		return await $axios({
			method: 'PUT',
			url: `${resource}/${data.userId}/wishlist/${data.listId}`,
			data: { new_name: data.name }
		})
	},
	async clearList(data) {
		return await $axios({
			method: 'PUT',
			url: `${resource}/${data.userId}/wishlist/${data.listId}/clear`
		})
	},
	async addProductToList(data) {
		return await $axios({
			method: 'PUT',
			url: `${resource}/${data.userId}/wishlist/${data.listId}/add/${data.productId}`
		})
	},
	async removeProductFromList(data) {
		return await $axios({
			method: 'DELETE',
			url: `${resource}/${data.userId}/wishlist/${data.listId}/item/${data.productId}`
		})
	},
	async getUserWishlists({ userId }) {
		return await $axios({
			method: 'GET',
			url: `${resource}/${userId}/wishlist`
		})
	}
})
