import { ObjectError } from '~/utils/error'

export const state = () => ({
	sectionList: [],
	categories: {},
	sectionIds: {},
	allCategories: {},
	revertSectionIds: {}
})

export const mutations = {
	SET_SECTION_LIST(state, data) {
		const translations = {
			Рідини: 'liquid',
			Девайси: 'device',
			Комплектуючі: 'accessories'
		}
		const flavors = [
			{ hoverColor: 'classic', title: 'Табак' },
			{ hoverColor: 'mint', title: "М'ята" },
			{ hoverColor: 'fruit', title: 'Фрукти' },
			{ hoverColor: 'drink', title: 'Напої' },
			{ hoverColor: 'fresh', title: 'Холодні' },
			{ hoverColor: 'gourmet', title: 'Десерти' }
		]
		const accessories = [
			{ image: '/cartridges.svg', title: 'Картриджі' },
			{ image: '/resistive.svg', title: 'Випаровувачі' },
			{ image: '/battery.svg', title: 'Аккумулятори' }
		]
		const devices = [
			{ image: '/pods.svg', title: 'Под системи' },
			{ image: '/vape.svg', title: 'Вейп набори' },
			{ image: '/single.svg', title: 'Одноразки' },
			{ image: '/box_mode.svg', title: 'Бокс моди' },
			{ image: '/atomizer.svg', title: 'Атомайзери' }
		]

		state.sectionIds = data.reduce((acc, section) => {
			return { ...acc, [translations[section.Name]]: section.id }
		}, {})

		state.revertSectionIds = data.reduce((acc, section) => {
			return { ...acc, [section.id]: section.Name }
		}, {})

		state.categories = data.reduce((acc, section) => {
			return { ...acc, [translations[section.Name]]: section.Categories }
		}, {})

		state.categories.liquid = state.categories.liquid.reduce(
			(acc, category) => {
				const { title, ...params } = flavors.find(
					flavor => flavor.title === category.name
				)

				return [...acc, { ...category, ...params }]
			},
			[]
		)
		state.categories.accessories = state.categories.accessories.reduce(
			(acc, category) => {
				const { image } = accessories.find(
					accessory => accessory.title === category.name
				)

				return [...acc, { ...category, image }]
			},
			[]
		)
		state.categories.device = state.categories.device.reduce(
			(acc, category) => {
				const { image } = devices.find(device => device.title === category.name)

				return [...acc, { ...category, image }]
			},
			[]
		)

		state.allCategories = Object.keys(state.categories).reduce((acc, key) => {
			const categoriesTrim = state.categories[key].reduce((list, item) => {
				return { ...list, [item.id]: item.name }
			}, {})

			return { ...acc, ...categoriesTrim }
		}, {})

		state.sectionList = data
	}
}

export const actions = {
	async getSections({ commit }) {
		try {
			const response = await this.$repositories.products.getSections()

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_SECTION_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	}
}

export const getters = {
	getSectionList: state => state.sectionList,
	getSectionIds: state => state.sectionIds,
	getRevertSectionIds: state => state.revertSectionIds,
	getCategories: state => state.categories,
	getAllCategoriesWithId: state => state.allCategories
}
