
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			translation: {
				color: 'Колір',
				organic: 'Органічний',
				salt: 'Сольовий',
				cherry: 'Ягода',
				apple: 'Яблуко',
				tabacco: 'Табак',
				mint: 'М`ята',
				fruit: 'Фрукти',
				drink: 'Напої',
				nicotine_type: 'Нікотин',
				dosage_nicotine: 'Міцність',
				dosage: "Об'єм",
				taste: 'Смак',
				resistance: 'Опір'
			}
		}
	},
	computed: {
		...mapGetters({
			list: 'cart/getCartList',
			getProductCount: 'cart/getProductCount'
		}),
		getTotalSum() {
			return [...(this.list || [])].reduce(
				(sum, product) =>
					sum + parseFloat(product.price.total_price) * product.qty,
				0
			)
		},
		getTitle() {
			const count = this.getProductCount

			if (count === 0 || count > 4) return 'товарів'
			if (count === 1) return 'товар'
			if (count > 1 && count < 5) return 'товари'

			return ''
		}
	},
	mounted() {
		this.$store.commit('cart/SET_CART_LIST')
	},
	methods: {
		...mapActions({
			removeProduct: 'cart/removeProduct'
		}),
		remove(product) {
			this.$store.commit('cart/DELETE_PRODUCT_CART', product)
		},
		setAltImg(event) {
			event.target.src = '/images/img_not_found.png'
		}
	}
}
