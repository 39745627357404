
import { mapActions } from 'vuex'

import Input from '~/components/ui/Input.vue'
import Button from '~/components/ui/Button.vue'

export default {
	components: { Input, Button },
	data() {
		return {
			products: [],
			test: 1,
			wishItem: {},
			showAdditionalMenu: false,
			isRenameBlock: false,
			title: '',
			loading: false,
			favoriteList: []
		}
	},
	watch: {
		'wishItem.name'(val) {
			if (val) this.title = val
		}
	},
	methods: {
		...mapActions({
			removeProductFromList: 'wishList/removeProductFromList',
			removeList: 'wishList/removeList',
			clearList: 'wishList/clearWishList',
			renameWishList: 'wishList/renameWishList'
		}),
		beforeOpen(data) {
			document.body.style.overflow = 'hidden'
			const { params } = data

			this.products = params.listDetail.items
			this.wishItem = params.listDetail.wishItem
		},
		beforeClose() {
			document.body.style.overflow = 'visible'
		},
		hide() {
			this.$modal.hide('wish-list')
			this.showAdditionalMenu = false
			this.isRenameBlock = false
		},
		showAddMenu() {
			this.showAdditionalMenu = !this.showAdditionalMenu

			if (!this.showAdditionalMenu && this.isRenameBlock)
				this.isRenameBlock = false
		},
		updatePrice(val, action) {
			if (action === 'minus') val.count > 1 && val.count--
			if (action === 'plus') val.count++
		},
		async removeItem(type, item) {
			const message =
				type === 'product'
					? 'Ви дійсно хочете видалити товар?'
					: 'Ви дійсно хочете видалити список?'
			const res = confirm(message)

			if (res) {
				const data = { listId: this.wishItem.id, userId: this.$auth.user.id }

				type === 'product' && (data.productId = item.id)

				try {
					this.loading = true

					const response =
						type === 'product'
							? await this.removeProductFromList(data)
							: await this.removeList(data)

					if (response.status === 200) {
						if (type === 'product') {
							this.favoriteList =
								JSON.parse(localStorage.getItem('favorite')) || []
							this.favoriteList = this.favoriteList.filter(id => id !== item.id)
							localStorage.setItem(
								'favorite',
								JSON.stringify(this.favoriteList)
							)
							this.$store.commit('wishList/SET_CHANGE_WISH_LIST', true)
						}

						this.$toast.success(
							type === 'product'
								? 'Товар успішно видалено зі списку'
								: 'Список успішно видалено'
						)

						this.hide()
					} else this.$toast.error(response.data.err)
				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.$store.commit('wishList/SET_CHANGE_WISH_LIST', false)
					}, 500)
					this.loading = false
				}
			}
		},
		async emptyList() {
			this.loading = true
			const res = confirm('Ви дійсно хочете очистити список?')

			if (res) {
				const data = { listId: this.wishItem.id, userId: this.$auth.user.id }

				try {
					const response = await this.clearList(data)

					if (response.status === 200) {
						this.$toast.success('Список успішно очищено')

						this.hide()
					} else this.$toast.error(response.message)
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}
			}
		},
		async renameList() {
			this.loading = true

			const data = {
				listId: this.wishItem.id,
				userId: this.$auth.user.id,
				name: this.title
			}

			try {
				const response = await this.renameWishList(data)

				if (response.status === 200) {
					this.$toast.success('Список успішно перйменовано')

					this.isRenameBlock = false
				} else this.$toast.error(response.message)
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		setAltImg(event) {
			event.target.src = '/images/img_not_found.png'
		},
		addAllToCart() {}
	}
}
