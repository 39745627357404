const resource = '/shop'

export default ($axios) => ({
  async getCart() {
    return await $axios({
      method: 'GET',
      url: `${resource}`,
    })
  },
  async addToCart(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/add-to-cart`,
      data,
    })
  },
  async removeFromCart({ id }) {
    return await $axios({
      method: 'DELETE',
      url: `${resource}/remove/${id}`,
    })
  },
  async createOrder(data) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/create-order`,
      data,
    })
  },
})
