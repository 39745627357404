
import { mapActions, mapGetters } from 'vuex'

import Input from '~/components/ui/Input.vue'
import Select from '~/components/ui/Select.vue'
import Checkbox from '~/components/ui/Checkbox.vue'
import Button from '~/components/ui/Button.vue'

import getCounties from '~/utils/getCounties'

export default {
  components: { Input, Select, Checkbox, Button },
  data() {
    return {
      loading: false,
      defCountry: 'UA',
      additionalAddress: '',
      isDefault: false,
      userData: {
        addressName: '',
        firstName: '',
        lastName: '',
        zip: null,
        city: '',
        country: {
          code: '',
          name: '',
        },
        phone: '',
        fullAddress: '',
      },
      countries: getCounties,
      propsAddress: {},
    }
  },
  computed: {
    ...mapGetters({
      getAddress: 'address/getAddress',
      addressList: 'address/getAddressList',
    }),
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUserInfo',
      updateAddress: 'address/updateAddress',
    }),
    beforeOpen(data) {
      document.body.style.overflow = 'hidden'

      this.propsAddress = data.params.data
      this.showForm(data.params.data)
    },
    beforeClose() {
      document.body.style.overflow = 'visible'
    },
    showForm(data) {
      this.setAddress(data)
    },
    async update() {
      try {
        this.loading = true

        const getCountry = this.countries.find(
          (el) =>
            el.iso2 === this.userData.country.code ||
            el.iso2 === this.defCountry
        )
        const newCountry = {
          code: getCountry.iso2,
          name: getCountry.name,
        }

        const { country, ...address } = this.userData

        const params = {
          listId: this.getAddress._id,
          country: newCountry,
          ...address,
        }

        const response = await this.updateAddress(params)

        if (response.status === 200) {
          this.$toast.success(response.data.message)

          if (this.isDefault) {
            const resp = await this.updateUser({
              id: this.$auth.user.id,
              selectedAddress: response.data.data.address._id,
            })

            if (resp.status === 200) this.$auth.setUser(resp.data.data)
          }

          this.goBack()
        } else {
          this.$toast.error(response.message)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.resetData()
      }
    },
    setAddress(data) {
      const getCountry = this.countries.find(
        (el) => el.iso2 === data?.country?.code || el.iso2 === this.defCountry
      )
      const { country, ...address } = data

      this.userData = {
        ...address,
        country: {
          code: getCountry.iso2,
          name: getCountry.name,
        },
      }
    },
    resetData() {
      this.isDefault = false
      this.userData = {
        addressName: '',
        firstName: '',
        lastName: '',
        zip: null,
        city: '',
        country: {
          code: '',
          name: '',
        },
        phone: '',
        fullAddress: '',
      }
    },
    hide() {
      this.$modal.hide('address-change-modal')
    },
    goBack() {
      this.hide()
      this.$modal.show('address-modal')
    },
  },
}
