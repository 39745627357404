import { ObjectError } from '~/utils/error'

export const state = () => ({
	brands: [],
	brandsBySection: {
		device: [],
		liquid: [],
		accessories: []
	}
})

export const mutations = {
	SET_BRAND_LIST(state, data) {
		state.brands = data
	},
	SET_BRANDS_BY_SECTION(state, { key, data }) {
		state.brandsBySection[key] = data
	}
}

export const actions = {
	async fetchBrands({ commit }, data) {
		try {
			const response = await this.$repositories.products.getBrandsBySection(
				data
			)

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_BRAND_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async fetchBrandsBySection({ commit }, data) {
		try {
			const [key, value] = Object.entries(data)[0]

			const response = await this.$repositories.products.getBrandsBySection({
				section_id: value
			})

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_BRANDS_BY_SECTION', { key, data: response.data })

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	}
}

export const getters = {
	getBrands: state => state.brands || [],
	getBrandsIds: state => {
		const list = state.brands.length
			? state.brands
			: [
					...state.brandsBySection.liquid,
					...state.brandsBySection.device,
					...state.brandsBySection.accessories
			  ]

		return list.reduce((acc, el) => ({ ...acc, [el.id]: el.name }), {})
	},
	getBrandsBySection: state => state.brandsBySection
}
