import { ObjectError } from '~/utils/error'

export const state = () => ({
  randomReview: [],
  productReview: [],
})

export const mutations = {
  SET_RANDOM_REVIEW_LIST(state, data) {
    state.randomReview = data
  },
  SET_PRODUCT_REVIEW_LIST(state, data) {
    state.productReview = data
  },
}

export const actions = {
  async fetchRandomReview({ commit }) {
    try {
      const response = await this.$repositories.review.getRandomReviews()

      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_RANDOM_REVIEW_LIST', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async getProductReview({ commit }, data) {
    try {
      const response = await this.$repositories.review.getProductReview(data)

      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_PRODUCT_REVIEW_LIST', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async createReview({ commit }, data) {
    try {
      const response = await this.$repositories.review.createReview(data)

      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
}

export const getters = {
  getRandomReviews: (state) => state.randomReview,
  getProductReviews: (state) => state.productReview,
}
