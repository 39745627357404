const resource = '/user'

export default ($axios) => ({
  async createChat({ userId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/${userId}/support`,
      data,
    })
  },
  async getList({ userId }) {
    return await $axios({
      method: 'GET',
      url: `${resource}/${userId}/support/list`,
    })
  },
  async chatInfo({ userId, chatId }) {
    return await $axios({
      method: 'GET',
      url: `${resource}/${userId}/support/show/${chatId}`,
    })
  },
  async sendMessage({ userId, chatId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/${userId}/support/reply/${chatId}`,
      data,
    })
  },
  async setFile({ userId, ...data }) {
    return await $axios({
      method: 'POST',
      url: `${resource}/${userId}/support/image`,
      data: data.data,
    })
  },
})
