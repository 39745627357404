
export default {
  name: 'ButtonComponent',
  props: {
    size: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'btn-vert',
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    typeLoading: {
      type: Object,
      default: () => {},
    },
    loaderWithText: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'button',
    },
    path: {
      type: [String, Object],
      default: null,
    },
    defaultClass: {
      type: String,
      default: 'btn',
    },
    customSlot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isText() {
      if (this.typeLoading) {
        const list = Object.values(this.typeLoading).filter((item) => item)

        return list.length
      }

      return false
    },
  },
  methods: {
    async goTo() {
      return await this.$router.push(this.path)
    },
  },
}
