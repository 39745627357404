
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'
import Banner from '~/components/Header/DropdownsNav/Banner.vue'

export default {
  components: { Banner, Button },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    step: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      translations: {
        device: 'Всі девайси',
        liquid: 'Всі рідини',
        accessories: 'Всі комплектуючі',
      },
      // isInnerMenu: false
    }
  },
  computed: {
    ...mapGetters({
      categories: 'section/getCategories',
      sectionIds: 'section/getSectionIds',
      brandsBySection: 'brands/getBrandsBySection',
    }),
  },
  watch: {
    '$route.path'(val) {
      if (val) this.hideSidebar()
    },
  },
  methods: {
    hideSidebar() {
      this.$emit('update:isShow', false)
    },
    showInnerMenu(step) {
      // this.isInnerMenu = true
      this.$emit('update:step', { step })
    },
    setImage(path) {
      return require(`@/assets/images/sections/${this.options.type}` + path)
    },
    setAltImg(event) {
      event.target.src = '/images/img_not_found.png'
    },
  },
}
