
import { mask } from 'vue-the-mask'

export default {
	name: 'InputComponent',
	directives: { mask },
	props: {
		id: {
			type: String,
			default: 'form_input_id'
		},
		max: {
			type: Number,
			default: null
		},
		maxLength: {
			type: Number,
			default: null
		},
		title: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'text'
		},
		val: {
			type: [String, Number],
			default: ''
		},
		autocomplete: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: 'form_input_name'
		},
		component: {
			type: String,
			default: 'input'
		},
		rows: {
			type: String,
			default: '10'
		},
		cols: {
			type: String,
			default: '30'
		},
		isBorderTextarea: {
			type: Boolean,
			default: false
		},
		clearMargin: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		noResize: {
			type: Boolean,
			default: false
		},
		mask: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			activeInput: ''
		}
	},
	computed: {
		inputVal: {
			get() {
				return this.val
			},
			set(val) {
				this.$emit('update:val', val)
			}
		}
	},
	methods: {
		onInput() {
			this.$emit('input')
		},
		onBlur() {
			this.$emit('blur')
			this.setActiveClass('')
		},
		onChange() {
			this.$emit('change')
		},
		onFocus() {
			this.$emit('focus')
		},
		setActiveClass(input) {
			this.$emit('click')
			input !== '' ? (this.activeInput = input) : (this.activeInput = '')
		}
	}
}
