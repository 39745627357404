import { ObjectError } from '~/utils/error'

export const state = () => ({
  list: [],
  address: {},
  updatedAddress: {},
  currentAddress: {
    country: { name: '' },
  },
})

export const mutations = {
  SET_UPDATED_ADDRESS(state, data) {
    state.updatedAddress = data
  },
}

export const actions = {
  async saveAddress({ commit }, data) {
    try {
      const response = await this.$repositories.address.saveAddress(data)
      if (response.status !== 200) throw new ObjectError(response)

      this.$auth.fetchUser()

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
  async removeAddress({ commit }, data) {
    try {
      const response = await this.$repositories.address.removeAddress(data)

      if (response.status !== 200) throw new ObjectError(response)

      await this.$auth.fetchUser()

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
}

export const getters = {
  getAddressList: (state) => state.list || [],
  getAddress: (state) => state.address || {},
  getCurrentAddress: (state) => state.currentAddress || {},
  getUpdatedAddress: (state) => state.updatedAddress || {},
}
