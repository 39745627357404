import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4a414227&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=4a414227&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a414227",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/www/vape_shop_nuxt_2/components/Header/Header.vue').default,Cookies: require('/var/www/vape_shop_nuxt_2/components/Cookies.vue').default,Footer: require('/var/www/vape_shop_nuxt_2/components/Footer/Footer.vue').default})
