export class ObjectError {
  name = 'Error'
  data = {}

  constructor(data) {
    const { status, ...rest } = data
    this.data = {
      status,
      ...rest,
    }
  }

  get error() {
    return this.data
  }
}
