import AuthRepository from './Auth'
import UserRepository from './User'
import ProductsRepository from './Product'
import SupportRepository from './Support'
import NovaPoshtaRepository from './NovaPoshta'
import WishListRepository from './WishList'
import CartRepository from './Cart'
import AddressRepository from './Address'
import ReviewRepository from './Review'
import BannerRepository from './Banner'

export default ($axios) => ({
  auth: AuthRepository($axios),
  user: UserRepository($axios),
  products: ProductsRepository($axios),
  support: SupportRepository($axios),
  novaPoshta: NovaPoshtaRepository($axios),
  wishList: WishListRepository($axios),
  cart: CartRepository($axios),
  address: AddressRepository($axios),
  review: ReviewRepository($axios),
  banner: BannerRepository($axios),
})
