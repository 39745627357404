
import Button from '~/components/ui/Button.vue'

export default {
  components: { Button },
  data() {
    return {
      menuList: [
        {
          title: 'Профіль',
          path: '/profile',
          subMenu: [
            { title: 'Про мене', path: '/profile' },
            { title: 'Список адрес', path: '/profile/address' },
          ],
        },
        { title: 'Замовлення', path: '/orders', subMenu: [] },
        {
          title: 'Підтримка',
          path: '/support',
          subMenu: [
            { title: 'Зворотній зв`язок', path: '/support' },
            { title: 'Історія звернень', path: '/support/history' },
          ],
        },
        { title: 'Улюбленне', path: '/favorites', subMenu: [] },
      ],
      activeClass: '',
      activeTabs: [],
      currentTitle: '',
      showMenu: false,
    }
  },
  computed: {
    getCurrentRoute() {
      return this.$route.path.split('/').slice(1)
    },
  },
  watch: {
    '$route.path'(val) {
      this.activeTabs = []
      this.setParentActiveClass(this.menuList)
    },
  },
  mounted() {
    this.setParentActiveClass(this.menuList)
  },
  methods: {
    getParentRoute(path) {
      return path.split('/')[1]
    },
    setParentActiveClass(list) {
      list.forEach((el, index) => {
        if (
          el.subMenu.length &&
          this.getCurrentRoute[0] === this.getParentRoute(el.path)
        ) {
          this.activeClass = 'active'
          this.setActiveTab(index)
          this.currentTitle = el.title
        } else if (!el.subMenu.length && this.$route.path === el.path) {
          this.setActiveTab(index)
          this.activeClass = 'active without_list'
          this.currentTitle = el.title
        }
      })
    },
    setActiveTab(index, item) {
      if (!this.activeTabs.includes(index)) {
        this.activeTabs.push(index)
      } else if (
        this.activeTabs.includes(index) &&
        this.$route.path !== item.path
      ) {
        const foundIndex = this.activeTabs.findIndex((el) => el === index)

        this.activeTabs.splice(foundIndex, 1)
      }
    },
    async logoutUser() {
      this.$store.commit('wishList/SET_WISH_LIST', [])
      this.$store.commit('cart/SET_CART_LIST', [])
      this.$store.commit('wishList/RESET_IDS')

      await this.$auth.logout()
    },
  },
}
