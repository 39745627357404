
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
	name: 'BreadcrumbComponent',
	components: { Button },
	data() {
		return {
			routes: [],
			dynamicRoute: '',
			translateRoutes: {
				profile: 'Мій профіль',
				address: 'Мої адреси',
				orders: 'Мої замовлення',
				support: 'Підтримка',
				history: 'Історія звернень',
				cards: 'Мої картки',
				'wish-list': 'Обране',
				product: 'Товари',
				favorites: 'Улюблене',
				alerts: 'Сповіщення',
				contact: 'Контакти',
				'privacy-policy': 'Політика конфіденційності',
				'general-conditions-use': 'Правила користування',
				'delivery-return': 'Доставка і повернення',
				'cookie-policy': 'Політика Кукі',
				'terms-conditions': 'Правила і умови',
				'legal-info': 'Легальна інформація',
				recovery: 'Відновлення паролю',
				device: 'Девайси',
				liquid: 'Рідини',
				accessories: 'Комплектуючі',
				new: 'Новинки',
				brands: 'Бренди',
				filterByBrand: 'Фільтр по бренду',
				detail: 'Детально',
				'about-us': 'Про нас'
			}
		}
	},
	computed: {
		...mapGetters({
			error: 'error/getError',
			productDetail: 'product/getProductDetail',
			getAllCategoriesWithId: 'section/getAllCategoriesWithId',
			brandIds: 'brands/getBrandsIds'
		}),
		generateRoutes() {
			this.dynamicRoute = ''

			const { name, path } = this.$route

			const generatedRoutesName =
				!path.includes('-') && name?.includes('-')
					? name?.split('-')
					: path.includes('-')
					? path.split('/').slice(1)
					: [name]

			return generatedRoutesName.reduce((acc, el, index, array) => {
				let title = ''

				if (index === 0 || !this.$route.params) {
					this.dynamicRoute += `/${el}`
				} else {
					this.dynamicRoute += this.$route.params[el]
						? `/${this.$route.params[el]}`
						: `/${el}`
				}

				if (
					index > 1 &&
					array[index - 2] === 'product' &&
					array[index - 1] !== 'detail'
				) {
					title =
						this.getAllCategoriesWithId[el] ||
						this.brandIds[el] ||
						this.$route.params[el]
				} else if (el === 'detail') {
					title = 'detail'
				} else if (index === 0 || !this.$route.params) {
					title = el
				} else if (index > 1 && array[array.length - 2] === 'detail') {
					title = this.productDetail?.name
				} else if (el !== 'id')
					title = this.$route.params[el] ? this.$route.params[el] : el
				else title = this.productDetail?.name

				return [
					...acc,
					{
						title,
						route: this.dynamicRoute
					}
				]
			}, [])
		}
	},
	watch: {
		'$route.path': {
			immediate: true,
			handler() {
				this.dynamicRoute = ''
			}
		}
	},
	methods: {
		async goBack() {
			const parentRouter = this.$route.path
				.split('/')
				.filter((item, index, array) => {
					if (index > 1 && array[array.length - 2] === 'detail') {
						return index < array.length - 2
					}

					return index !== array.length - 1
				})
				.join('/')

			if (!parentRouter) {
				return await this.$router.push('/')
			}

			return await this.$router.push(parentRouter)
		}
	}
}
