
export default {
	data() {
		return {
			infoMenu: [
				{ title: 'Контакти', path: '/contact' },
				{ title: 'Політика конфіденційності', path: '/privacy-policy' },
				{ title: 'Правила користування', path: '/general-conditions-use' },
				{ title: 'Доставка і повернення', path: '/delivery-return' },
				// { title: 'Політика Кукі', path: '/cookie-policy' },
				{ title: 'Правила і умови', path: '/terms-conditions' }
				// { title: 'Легальна інформація', path: '/legal-info' },
			]
		}
	}
}
