import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b038fcf = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5dc8d052 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _20e7a3f6 = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _0b07c192 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _26c75e80 = () => interopDefault(import('../pages/delivery-return.vue' /* webpackChunkName: "pages/delivery-return" */))
const _5914fa31 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _752b6530 = () => interopDefault(import('../pages/general-conditions-use.vue' /* webpackChunkName: "pages/general-conditions-use" */))
const _f4879c80 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _96ca62be = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _2ec4c660 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1b7d5289 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _3ceceec3 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _47559b11 = () => interopDefault(import('../pages/recovery/index.vue' /* webpackChunkName: "pages/recovery/index" */))
const _2ad56f6e = () => interopDefault(import('../pages/shop-cart.vue' /* webpackChunkName: "pages/shop-cart" */))
const _7ec7ed69 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _4c75d8b7 = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _27ea34e0 = () => interopDefault(import('../pages/validate-order.vue' /* webpackChunkName: "pages/validate-order" */))
const _026fdd4c = () => interopDefault(import('../pages/product/new/index.vue' /* webpackChunkName: "pages/product/new/index" */))
const _52354ea5 = () => interopDefault(import('../pages/profile/address.vue' /* webpackChunkName: "pages/profile/address" */))
const _1f43972a = () => interopDefault(import('../pages/support/history.vue' /* webpackChunkName: "pages/support/history" */))
const _06d936d5 = () => interopDefault(import('../pages/product/detail/_id.vue' /* webpackChunkName: "pages/product/detail/_id" */))
const _2082004e = () => interopDefault(import('../pages/product/new/_section.vue' /* webpackChunkName: "pages/product/new/_section" */))
const _e679fe5a = () => interopDefault(import('../pages/product/_type/index.vue' /* webpackChunkName: "pages/product/_type/index" */))
const _49204c0b = () => interopDefault(import('../pages/recovery/_code.vue' /* webpackChunkName: "pages/recovery/_code" */))
const _f48c173e = () => interopDefault(import('../pages/product/_type/_category/index.vue' /* webpackChunkName: "pages/product/_type/_category/index" */))
const _87d6e2ae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _4b038fcf,
    name: "404"
  }, {
    path: "/about-us",
    component: _5dc8d052,
    name: "about-us"
  }, {
    path: "/brands",
    component: _20e7a3f6,
    name: "brands"
  }, {
    path: "/contact",
    component: _0b07c192,
    name: "contact"
  }, {
    path: "/delivery-return",
    component: _26c75e80,
    name: "delivery-return"
  }, {
    path: "/favorites",
    component: _5914fa31,
    name: "favorites"
  }, {
    path: "/general-conditions-use",
    component: _752b6530,
    name: "general-conditions-use"
  }, {
    path: "/login",
    component: _f4879c80,
    name: "login"
  }, {
    path: "/orders",
    component: _96ca62be,
    name: "orders"
  }, {
    path: "/privacy-policy",
    component: _2ec4c660,
    name: "privacy-policy"
  }, {
    path: "/product",
    component: _1b7d5289,
    name: "product"
  }, {
    path: "/profile",
    component: _3ceceec3,
    name: "profile"
  }, {
    path: "/recovery",
    component: _47559b11,
    name: "recovery"
  }, {
    path: "/shop-cart",
    component: _2ad56f6e,
    name: "shop-cart"
  }, {
    path: "/support",
    component: _7ec7ed69,
    name: "support"
  }, {
    path: "/terms-conditions",
    component: _4c75d8b7,
    name: "terms-conditions"
  }, {
    path: "/validate-order",
    component: _27ea34e0,
    name: "validate-order"
  }, {
    path: "/product/new",
    component: _026fdd4c,
    name: "product-new"
  }, {
    path: "/profile/address",
    component: _52354ea5,
    name: "profile-address"
  }, {
    path: "/support/history",
    component: _1f43972a,
    name: "support-history"
  }, {
    path: "/product/detail/:id?",
    component: _06d936d5,
    name: "product-detail-id"
  }, {
    path: "/product/new/:section",
    component: _2082004e,
    name: "product-new-section"
  }, {
    path: "/product/:type",
    component: _e679fe5a,
    name: "product-type"
  }, {
    path: "/recovery/:code",
    component: _49204c0b,
    name: "recovery-code"
  }, {
    path: "/product/:type/:category",
    component: _f48c173e,
    name: "product-type-category"
  }, {
    path: "/",
    component: _87d6e2ae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
