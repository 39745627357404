
import AddToWishList from '~/components/Modals/WishList/AddToWishList.vue'
import Congratulation from '~/components/Modals/WishList/Congratulation.vue'
import WishList from '~/components/Modals/WishList/WishList.vue'
import ErrorWishList from '~/components/Modals/WishList/ErrorWishList.vue'
import Search from '~/components/Modals/Search.vue'
import CheckAge from '~/components/Modals/CheckAge.vue'
import DetailCookies from '~/components/Modals/DetailCookies.vue'
import AddToCart from '~/components/Modals/AddToCart.vue'
import ChangeAddress from '~/components/Modals/Address/ChangeAddress.vue'
import FormNewAddress from '~/components/Modals/Address/FormNewAddress.vue'

export default {
  components: {
    AddToWishList,
    Congratulation,
    WishList,
    ErrorWishList,
    Search,
    CheckAge,
    DetailCookies,
    AddToCart,
    ChangeAddress,
    FormNewAddress,
  },
}
