const resource = '/user'

export default ($axios) => ({
  async getInfo(id) {
    return await $axios({
      method: 'GET',
      url: `${resource}/${id}`,
    })
  },
  async updateUser({ userId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/${userId}`,
      data,
    })
  },
  async updatePassword({ userId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/${userId}/password`,
      data,
    })
  },
  async forgotPassword(data) {
    return await $axios({
      method: 'POST',
      url: `/reset-password/test`,
      data,
    })
  },
  async resetPassword({ userId, token, ...data }) {
    return await $axios({
      method: 'POST',
      url: `/new-password/${userId}/${token}`,
      data,
    })
  },
  async getOrders({ userId, ...data }) {
    return await $axios({
      method: 'POST',
      url: `${resource}/${userId}/orders`,
      data,
    })
  },
})
