import { ObjectError } from '~/utils/error'

export const state = () => ({})

export const actions = {
  async register({ commit }, data) {
    try {
      const response = await this.$repositories.auth.registration(data)
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async logout({ commit }) {
    try {
      const response = await this.$repositories.auth.logout()
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
}
