const resource = '/shop'

export default ($axios) => ({
  async getAll(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/products`,
      data,
    })
  },
  async getSections() {
    return await $axios({
      method: 'GET',
      url: `${resource}/sections`,
    })
  },
  async getNewProductBySection(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/products/new`,
      data,
    })
  },
  async getTeamChoiceProducts(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/products/team_choice`,
      data,
    })
  },
  async getBrandsBySection(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/brands`,
      data,
    })
  },
  async getSelected() {
    return await $axios({
      method: 'GET',
      url: `${resource}/products/selected`,
    })
  },
  async search(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/products/search/subproducts`,
      data,
    })
  },
  async getInfo({ productId, ...data }) {
    return await $axios({
      method: 'POST',
      url: `${resource}/product/${productId}`,
      data,
    })
  },
  async createProductReview({ productId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/product/${productId}/review`,
      data,
    })
  },
})
