
export default {
  methods: {
    beforeOpen() {
      document.body.style.overflow = 'hidden'
    },
    beforeClose() {
      document.body.style.overflow = 'visible'
    },
    hide() {
      this.$modal.hide('error-wish-list')
    },
  },
}
