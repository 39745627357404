
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      sectionsIds: 'section/getSectionIds',
    }),
  },
}
