import { omit } from 'lodash-es'

import { ObjectError } from '~/utils/error'

const setOptions = (data, type = 'products') => {
	const optionsField = [
		'new',
		'new_color',
		'new_taste',
		'team_choice',
		'new_option',
		'low_quantity',
		'bestseller'
	]
	const cartList =
		(process.client && JSON.parse(localStorage.getItem('order_list'))) || []

	return data.reduce((acc, product) => {
		product.labels = Object.keys(product).reduce((options, key) => {
			if (optionsField.includes(key)) {
				return { ...options, [key]: product[key] }
			}
			return { ...options }
		}, {})

		if (type !== 'search') {
			product.subIds = product.sub_products.reduce(
				(ids, subProduct) => [...ids, subProduct.id],
				[]
			)

			product.options = [
				...new Set(
					...product.sub_products.reduce((options, subProduct) => {
						if (subProduct?.options !== null) {
							return [...options, Object.keys(subProduct.options)]
						} else {
							return [...options]
						}
					}, [])
				)
			]
				.filter(el => el !== 'dosage')
				.reduce((opts, key) => ({ ...opts, [key]: null }), {})

			product.sub_products.forEach(subProd => {
				subProd.price = {
					base_total_price: product.base_total_price,
					total_price: product.total_price,
					discount_percent: product.discount_percent
				}

				if (cartList.length) {
					cartList.forEach(
						elFromCart =>
							elFromCart.sub_product_id === subProd.id &&
							(subProd.qty = elFromCart.qty)
					)
				} else subProd.qty = 0
			})
		} else {
			product.price = {
				base_total_price: product.base_total_price,
				total_price: product.total_price,
				discount_percent: product.discount_percent
			}

			product.subIds = [product.id]

			if (cartList.length) {
				cartList.forEach(
					elFromCart =>
						elFromCart.sub_product_id === product.id &&
						(product.qty = elFromCart.qty)
				)
			} else product.qty = 0
		}

		const result = omit(product, optionsField)

		return [...acc, result]
	}, [])
}

const prepareDate = data => {
	const productData = setOptions([data])[0]

	const images = productData.sub_products.reduce(
		(acc, sub) => [...acc, sub.image_url],
		[]
	)
	productData.image_urls.push(...images)

	return productData
}

export const state = () => ({
	productList: [],
	selectedList: [],
	searchList: [],
	productDetail: {},
	subProducts: [],
	subIds: []
})

export const mutations = {
	SET_PRODUCT_LIST(state, { items }) {
		state.productList.push(...setOptions(items))
	},
	RESET_PRODUCT_LIST(state) {
		state.productList = []
	},
	SET_PRODUCT_INFO(state, data) {
		const product = prepareDate(data)

		state.productDetail = product
	},
	SET_SELECTED_PRODUCT_LIST(state, data) {
		state.selectedList = setOptions(data)
	},
	RESET_SEARCH_LIST(state) {
		state.searchList = []
	},
	SET_SEARCH_LIST(state, { items, ...data }) {
		if (!data.total_count) state.searchList = items
		else state.searchList.push(...setOptions(items, 'search'))
	},
	SET_SUB_PRODUCTS(state, data) {
		state.subProducts = data
	},
	UPDATE_QTY(state, { id, qty }) {
		if (Object.keys(state.productDetail).length) {
			const { sub_products: subProducts, ...product } = state.productDetail

			const newSubProducts = subProducts.map(el => {
				if (el.id === id) {
					el.qty = qty

					return el
				}

				return el
			})

			state.productDetail = {
				...product,
				sub_products: newSubProducts
			}
		}

		state.productList = [...(state.productList || [])].reduce((acc, el) => {
			el.sub_products.forEach(sub => sub.id === id && (sub.qty = qty))

			return [...acc, el]
		}, [])
	}
}

export const actions = {
	async fetchList({ commit }, { reset, ...data }) {
		try {
			const response = await this.$repositories.products.getAll(data)

			if (response.status !== 200) throw new ObjectError(response)

			if (reset) commit('RESET_PRODUCT_LIST')

			commit('SET_PRODUCT_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError
		}
	},
	async fetchNewBySection({ commit }, { reset, ...data }) {
		try {
			const response = await this.$repositories.products.getNewProductBySection(
				data
			)

			if (reset) commit('RESET_PRODUCT_LIST')

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_PRODUCT_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async fetchTeamChoiceProducts({ commit }, { reset, ...data }) {
		try {
			const response = await this.$repositories.products.getTeamChoiceProducts(
				data
			)

			if (reset) commit('RESET_PRODUCT_LIST')

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_PRODUCT_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async getSelectedProduct({ commit }) {
		try {
			const response = await this.$repositories.products.getSelected()

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_SELECTED_PRODUCT_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async setSearch({ commit }, { reset, ...data }) {
		try {
			const response = await this.$repositories.products.search(data)

			if (response.status !== 200) throw new ObjectError(response)

			if (reset) commit('RESET_SEARCH_LIST')

			commit('SET_SEARCH_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async getProductInfo({ commit }, data) {
		try {
			const response = await this.$repositories.products.getInfo(data)

			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_PRODUCT_INFO', response.data)
			commit('SET_SUB_PRODUCTS', response.data.sub_products)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	}
}

export const getters = {
	getProductList: state => state.productList,
	getProductDetail: state => state.productDetail,
	getSelectedList: state => [...(state.selectedList || [])],
	getSearchList: state => state.searchList,
	getSubProducts: state => state.subProducts,
	getSubIds: state => state.subIds
}
