
import AccountAside from '~/components/Account/AccountAside.vue'
import Breadcrumb from '~/components/ui/Breadcrumb.vue'
import Footer from '~/components/Footer/Footer.vue'
import AllModals from '~/components/Modals/AllModals.vue'
import Cookies from '~/components/Cookies.vue'

export default {
	name: 'AccLayout',
	components: {
		AccountAside,
		Breadcrumb,
		Footer,
		AllModals,
		Cookies
	},
	// async fetch() {
	//   await this.$store.dispatch('cart/getCart')
	// },
	computed: {
		userName() {
			return this.$auth.user.first_name
		}
	},
	created() {
		const checkAge = this.$cookies.get('isCheckedAge')
		if (!checkAge) {
			this.$modal.show('checkAge')
		}
	}
}
