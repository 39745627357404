export default {
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange({ pagination, ...params }) {
      this.updateParams({
        page: pagination.page,
        size: pagination.size,
      })
      if (this.loadItems) this.loadItems()
    },
    // onPerPageChange(params) {
    //   this.updateParams({
    //     page: 1,
    //     size: params.size,
    //   })
    //   if (this.loadItems) this.loadItems()
    // },
    onSortChange(params) {
      this.updateParams({
        page: 1,
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      if (this.loadItems) this.loadItems()
    },
  },
}
