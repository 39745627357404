export const state = () => ({
  isPWA: false,
  pwa: null,
})

export const mutations = {
  OPENED_AS_PWA(state, data) {
    state.isPWA = data
  },
  SET_PWA(state, data) {
    state.pwa = data
  },
}

export const actions = {}

export const getters = {
  getOpenedAsPWA: (state) => state.isPWA,
  getPWA: (state) => state.pwa,
}
