import Vue from 'vue'

import classic from '~/components/icons/Flavor/Classic.vue'
import mint from '~/components/icons/Flavor/Mint.vue'
import fresh from '~/components/icons/Flavor/Fresh.vue'
import fruit from '~/components/icons/Flavor/Fruit.vue'
import gourmet from '~/components/icons/Flavor/Gourmet.vue'
import drink from '~/components/icons/Flavor/Drink.vue'

;[classic, mint, fresh, fruit, gourmet, drink].forEach((component) => {
  Vue.component(component.name, component)
})
