
import Button from '~/components/ui/Button.vue'

export default {
  components: {Button},
  methods: {
    beforeOpen() {
      document.body.style.overflow = 'hidden'
    },
    beforeClose() {
      document.body.style.overflow = 'visible'
    },
    acceptTerms() {
      const current = new Date().getTime()
      const monthInMSec = Number('2.62974383e+9')
      const deathDate = new Date(current + monthInMSec)

      this.$cookies.set('isCheckedAge', true, {
        expires: deathDate,
        path: '/',
        secure: false,
        sameSite: 'lax',
      })

      this.$modal.hide('checkAge')
    },
    denied() {
      const currentTab = window.open('', '_self')
      currentTab.close()
    },
  },
}
