export const AddressForm = () => import('../../components/AddressForm.vue' /* webpackChunkName: "components/address-form" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const ButtonPagination = () => import('../../components/ButtonPagination.vue' /* webpackChunkName: "components/button-pagination" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../components/Comments.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../components/Cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const DeliveryMethod = () => import('../../components/DeliveryMethod.vue' /* webpackChunkName: "components/delivery-method" */).then(c => wrapFunctional(c.default || c))
export const Guides = () => import('../../components/Guides.vue' /* webpackChunkName: "components/guides" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Manufacture = () => import('../../components/Manufacture.vue' /* webpackChunkName: "components/manufacture" */).then(c => wrapFunctional(c.default || c))
export const AccountAside = () => import('../../components/Account/AccountAside.vue' /* webpackChunkName: "components/account-aside" */).then(c => wrapFunctional(c.default || c))
export const AccountLayout = () => import('../../components/Account/AccountLayout.vue' /* webpackChunkName: "components/account-layout" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderShortCart = () => import('../../components/Header/ShortCart.vue' /* webpackChunkName: "components/header-short-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderSidebar = () => import('../../components/Header/Sidebar.vue' /* webpackChunkName: "components/header-sidebar" */).then(c => wrapFunctional(c.default || c))
export const HeaderSidebarNav = () => import('../../components/Header/SidebarNav.vue' /* webpackChunkName: "components/header-sidebar-nav" */).then(c => wrapFunctional(c.default || c))
export const HeaderTopNav = () => import('../../components/Header/TopNav.vue' /* webpackChunkName: "components/header-top-nav" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddToCart = () => import('../../components/Modals/AddToCart.vue' /* webpackChunkName: "components/modals-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalsAllModals = () => import('../../components/Modals/AllModals.vue' /* webpackChunkName: "components/modals-all-modals" */).then(c => wrapFunctional(c.default || c))
export const ModalsCheckAge = () => import('../../components/Modals/CheckAge.vue' /* webpackChunkName: "components/modals-check-age" */).then(c => wrapFunctional(c.default || c))
export const ModalsDetailCookies = () => import('../../components/Modals/DetailCookies.vue' /* webpackChunkName: "components/modals-detail-cookies" */).then(c => wrapFunctional(c.default || c))
export const ModalsSearch = () => import('../../components/Modals/Search.vue' /* webpackChunkName: "components/modals-search" */).then(c => wrapFunctional(c.default || c))
export const ProductsAside = () => import('../../components/Products/Aside.vue' /* webpackChunkName: "components/products-aside" */).then(c => wrapFunctional(c.default || c))
export const ProductsFeedback = () => import('../../components/Products/Feedback.vue' /* webpackChunkName: "components/products-feedback" */).then(c => wrapFunctional(c.default || c))
export const ProductsItem = () => import('../../components/Products/Item.vue' /* webpackChunkName: "components/products-item" */).then(c => wrapFunctional(c.default || c))
export const ProductsLastSeen = () => import('../../components/Products/LastSeen.vue' /* webpackChunkName: "components/products-last-seen" */).then(c => wrapFunctional(c.default || c))
export const ProductsMoreInfo = () => import('../../components/Products/MoreInfo.vue' /* webpackChunkName: "components/products-more-info" */).then(c => wrapFunctional(c.default || c))
export const ProductsOverlayProduct = () => import('../../components/Products/OverlayProduct.vue' /* webpackChunkName: "components/products-overlay-product" */).then(c => wrapFunctional(c.default || c))
export const ProductsReview = () => import('../../components/Products/Review.vue' /* webpackChunkName: "components/products-review" */).then(c => wrapFunctional(c.default || c))
export const PromoItem = () => import('../../components/Promo/PromoItem.vue' /* webpackChunkName: "components/promo-item" */).then(c => wrapFunctional(c.default || c))
export const SupportFeedbackForm = () => import('../../components/Support/FeedbackForm.vue' /* webpackChunkName: "components/support-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const IconsEasyPay = () => import('../../components/icons/EasyPay.vue' /* webpackChunkName: "components/icons-easy-pay" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../components/ui/Accordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumb = () => import('../../components/ui/Breadcrumb.vue' /* webpackChunkName: "components/ui-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/Button.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/Checkbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiCurvedText = () => import('../../components/ui/CurvedText.vue' /* webpackChunkName: "components/ui-curved-text" */).then(c => wrapFunctional(c.default || c))
export const UiDropdown = () => import('../../components/ui/Dropdown.vue' /* webpackChunkName: "components/ui-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiDropzone = () => import('../../components/ui/Dropzone.vue' /* webpackChunkName: "components/ui-dropzone" */).then(c => wrapFunctional(c.default || c))
export const UiFilter = () => import('../../components/ui/Filter.vue' /* webpackChunkName: "components/ui-filter" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/Input.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputSearch = () => import('../../components/ui/InputSearch.vue' /* webpackChunkName: "components/ui-input-search" */).then(c => wrapFunctional(c.default || c))
export const UiLineThrough = () => import('../../components/ui/LineThrough.vue' /* webpackChunkName: "components/ui-line-through" */).then(c => wrapFunctional(c.default || c))
export const UiNewDropdown = () => import('../../components/ui/NewDropdown.vue' /* webpackChunkName: "components/ui-new-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiNotification = () => import('../../components/ui/Notification.vue' /* webpackChunkName: "components/ui-notification" */).then(c => wrapFunctional(c.default || c))
export const UiProductBreadcrumb = () => import('../../components/ui/ProductBreadcrumb.vue' /* webpackChunkName: "components/ui-product-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const UiRadioButton = () => import('../../components/ui/RadioButton.vue' /* webpackChunkName: "components/ui-radio-button" */).then(c => wrapFunctional(c.default || c))
export const UiRoundIcon = () => import('../../components/ui/RoundIcon.vue' /* webpackChunkName: "components/ui-round-icon" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/Select.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiTooltip = () => import('../../components/ui/Tooltip.vue' /* webpackChunkName: "components/ui-tooltip" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownsNavAccessories = () => import('../../components/Header/DropdownsNav/Accessories.vue' /* webpackChunkName: "components/header-dropdowns-nav-accessories" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownsNavBanner = () => import('../../components/Header/DropdownsNav/Banner.vue' /* webpackChunkName: "components/header-dropdowns-nav-banner" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownsNavDevice = () => import('../../components/Header/DropdownsNav/Device.vue' /* webpackChunkName: "components/header-dropdowns-nav-device" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownsNavLiquid = () => import('../../components/Header/DropdownsNav/Liquid.vue' /* webpackChunkName: "components/header-dropdowns-nav-liquid" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownsNavNovelties = () => import('../../components/Header/DropdownsNav/Novelties.vue' /* webpackChunkName: "components/header-dropdowns-nav-novelties" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddressChangeAddress = () => import('../../components/Modals/Address/ChangeAddress.vue' /* webpackChunkName: "components/modals-address-change-address" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddressFormNewAddress = () => import('../../components/Modals/Address/FormNewAddress.vue' /* webpackChunkName: "components/modals-address-form-new-address" */).then(c => wrapFunctional(c.default || c))
export const ModalsWishListAddToWishList = () => import('../../components/Modals/WishList/AddToWishList.vue' /* webpackChunkName: "components/modals-wish-list-add-to-wish-list" */).then(c => wrapFunctional(c.default || c))
export const ModalsWishListCongratulation = () => import('../../components/Modals/WishList/Congratulation.vue' /* webpackChunkName: "components/modals-wish-list-congratulation" */).then(c => wrapFunctional(c.default || c))
export const ModalsWishListCreateWishList = () => import('../../components/Modals/WishList/CreateWishList.vue' /* webpackChunkName: "components/modals-wish-list-create-wish-list" */).then(c => wrapFunctional(c.default || c))
export const ModalsWishListErrorWishList = () => import('../../components/Modals/WishList/ErrorWishList.vue' /* webpackChunkName: "components/modals-wish-list-error-wish-list" */).then(c => wrapFunctional(c.default || c))
export const ModalsWishList = () => import('../../components/Modals/WishList/WishList.vue' /* webpackChunkName: "components/modals-wish-list" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorClassic = () => import('../../components/icons/Flavor/Classic.vue' /* webpackChunkName: "components/icons-flavor-classic" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorDrink = () => import('../../components/icons/Flavor/Drink.vue' /* webpackChunkName: "components/icons-flavor-drink" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorFresh = () => import('../../components/icons/Flavor/Fresh.vue' /* webpackChunkName: "components/icons-flavor-fresh" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorFruit = () => import('../../components/icons/Flavor/Fruit.vue' /* webpackChunkName: "components/icons-flavor-fruit" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorGourmet = () => import('../../components/icons/Flavor/Gourmet.vue' /* webpackChunkName: "components/icons-flavor-gourmet" */).then(c => wrapFunctional(c.default || c))
export const IconsFlavorMint = () => import('../../components/icons/Flavor/Mint.vue' /* webpackChunkName: "components/icons-flavor-mint" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
