import { ObjectError } from '~/utils/error'

export const state = () => ({
  supportList: [],
  chatInfo: [],
})

export const mutations = {
  SET_SUPPORT_LIST(state, data) {
    state.supportList = data
  },
  SET_CHAT_INFO(state, data) {
    state.chatInfo = data
  },
}

export const actions = {
  async getSupportList({ commit }, data) {
    try {
      const response = await this.$repositories.support.getList(data)

      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_SUPPORT_LIST', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async createChat({ commit }, data) {
    try {
      const response = await this.$repositories.support.createChat(data)

      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async getChatInfo({ commit }, data) {
    try {
      const response = await this.$repositories.support.chatInfo(data)

      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_CHAT_INFO', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async sendMessage({ commit }, data) {
    try {
      const response = await this.$repositories.support.sendMessage(data)

      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
}

export const getters = {
  getSupportList: (state) => state.supportList,
  getMessage: (state) => [...(state.chatInfo || [])].reverse(),
}
