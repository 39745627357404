import { ObjectError } from '~/utils/error'

export const state = () => ({
  bannerInfo: {},
})

export const mutations = {
  SET_BANNER_INFO(state, data) {
    state.bannerInfo = data
  },
}

export const actions = {
  async getBannerList({ commit }) {
    try {
      const response = await this.$repositories.banner.getBanners()

      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_BANNER_INFO', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
}

export const getters = {
  getBannerInfo: (state) => state.bannerInfo,
  getList: (state) => state.bannerInfo?.image_urls,
}
