
export default {
  data() {
    return {
      navList: [
        { path: '/about-us', title: 'Про нас', isLast: false },
        { path: '/contact', title: 'Контакти', isLast: false },
        { path: '/delivery-return', title: 'Доставка і оплата', isLast: false },
        { path: '/#opt', title: 'Цікавить опт?', isLast: true },
      ],
    }
  },
}
