
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    className: {
      type: Array,
      default: () => [],
    },
  },
}
