export default ({ $axios, $toast, app }) => {
	$axios.onError(async error => {
		const code = parseInt(error.response && error.response.status)

		const originalRequest = error.config

		if (
			[401, 403].includes(code) &&
			error.config &&
			!originalRequest._isRetryRequest
		) {
			originalRequest._isRetryRequest = true

			try {
				const response = await fetch(`https://api.hotvape.shop/session`, {
					method: 'PUT',
					credentials: 'same-origin',
					headers: {
						Authorization: app.$cookies.get('auth._token.local')
					}
				})
				const data = await response.json()

				if (response.status === 200) {
					app.$auth.setToken(
						app.$auth.$storage.state.strategy,
						'Bearer ' + data
					)
					app.$auth.ctx.app.$axios.setHeader('Authorization', 'Bearer ' + data)
					originalRequest.headers.Authorization = `Bearer ${data}`

					return await $axios(originalRequest)
				} else {
					app.$toast.error(data.err)
					app.$auth.strategy.token.set(false)
					app.$auth.ctx.app.$axios.setHeader('Authorization', false)
					originalRequest.headers.Authorization = false

					// await app.$auth.logout()

					return await app.router.push('/')
				}
			} catch (e) {
				console.log(e)
			}
		}

		if (code === 400) {
			return error.response
		}

		if (code === 404) {
			return $toast.error('Route not found 404')
		}

		if (code >= 500) {
			return $toast.error('Internal Server Error')
		}
	})
}
