
export default {
  name: 'CheckboxComponent',
  props: {
    val: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'form_checkbox_id',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.val
      },
      set(val) {
        this.$emit('update:val', val)
      },
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
