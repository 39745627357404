import { ObjectError } from '~/utils/error'

export const state = () => ({
  info: {},
  orders: [],
})

export const mutations = {
  SET_USER_INFO(state, data) {
    state.info = data
  },
  SET_USER_ORDER_LIST(state, data) {
    const orders = data.items

    state.orders = orders.reduce((acc, order) => {
      return [
        ...acc,
        {
          ...order,
          subIds: order.order_items.reduce(
            (ids, sub) => [...ids, sub.sub_product_id],
            []
          ),
        },
      ]
    }, [])
  },
}

export const actions = {
  async info({ commit }, id) {
    try {
      const response = await this.$repositories.user.getInfo(id)
      if (response.status !== 200) throw new ObjectError(response)

      this.$auth.setUser(response.data)
      commit('SET_USER_INFO', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async updatePassword({ commit }, data) {
    try {
      const response = await this.$repositories.user.updatePassword(data)
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async forgotPassword({ commit }, data) {
    try {
      const response = await this.$repositories.user.forgotPassword(data)
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async resetPassword({ commit }, data) {
    try {
      const response = await this.$repositories.user.resetPassword(data)
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async updateUserInfo({ commit }, data) {
    try {
      const response = await this.$repositories.user.updateUser(data)
      if (response.status !== 200) throw new ObjectError(response)

      await this.$auth.fetchUser()

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
  async getOrders({ commit }, data) {
    try {
      const response = await this.$repositories.user.getOrders(data)
      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_USER_ORDER_LIST', response.data)

      return response
    } catch (error) {
      const typedError = error

      return typedError.data
    }
  },
}

export const getters = {
  getInfo: (state) => state.info || {},
  getOrderList: (state) => state.orders || [],
}
