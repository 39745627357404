
import { mapGetters } from 'vuex'

import Banner from '~/components/Header/DropdownsNav/Banner.vue'

export default {
  components: { Banner },
  computed: {
    ...mapGetters({
      categories: 'section/getCategories',
      sectionIds: 'section/getSectionIds',
      brandsBySection: 'brands/getBrandsBySection',
    }),
  },
  methods: {
    setAltImg(event) {
      event.target.src = '/images/img_not_found.png'
    },
    setImage(path) {
      return require('@/assets/images/sections/accessories' + path)
    },
  },
}
