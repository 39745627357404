const baseUrl = 'https://api.novaposhta.ua/v2.0/json/'

const instance = async data => {
	const params = {
		apiKey: '31c5b01bab818b864256aa7215e8f4e1',
		...data
	}

	const response = await fetch(baseUrl, {
		method: 'POST',
		body: JSON.stringify(params),
		headers: {
			'Content-type': 'application/json'
		}
	})

	return {
		status: response.status,
		data: await response.json()
	}
}

export default () => ({
	async getDepartments(data) {
		return await instance({
			modelName: 'AddressGeneral',
			calledMethod: 'getWarehouses',
			methodProperties: data
		})
	},
	async getDocumentPrice(data) {
		return await instance({
			modelName: 'InternetDocument',
			calledMethod: 'getDocumentPrice',
			methodProperties: data
		})
	},
	async getWarehouseTypes() {
		return await instance({
			modelName: 'Address',
			calledMethod: 'getWarehouseTypes',
			methodProperties: {}
		})
	},
	async getCityList(data) {
		return await instance({
			modelName: 'Address',
			calledMethod: 'searchSettlements',
			methodProperties: {
				CityName: data,
				Limit: 100,
				Page: 1
			}
		})
	},
	async searchSettlementStreets(data) {
		return await instance({
			modelName: 'Address',
			calledMethod: 'searchSettlementStreets',
			methodProperties: {
				StreetName: data.street,
				SettlementRef: data.ref,
				Limit: 100,
				page: 1
			}
		})
	}
})
