
import Button from '~/components/ui/Button.vue'

export default {
  name: 'AboutCookies',
  components: { Button },
  methods: {
    setNextAskCookies(param) {
      const current = new Date().getTime()
      const yearInMSecond = Number('3.1536e+10')
      const deathDate = new Date(current + yearInMSecond)

      this.$cookies.set('aboutCookies', param, {
        expires: deathDate,
        path: '/',
        secure: false,
        sameSite: 'lax',
      })
    },
    decline() {
      this.setNextAskCookies(false)
      this.$store.commit('checkPWA/OPENED_AS_PWA', true)
      this.$modal.hide('infoCookies')
    },
    acceptTerms() {
      this.setNextAskCookies(true)
      this.$store.commit('checkPWA/OPENED_AS_PWA', true)
      this.$modal.hide('infoCookies')
    },
  },
}
