export const state = () => ({
  isShow: false,
})

export const mutations = {
  SET_SHOW_SEARCH(state, data) {
    state.isShow = data
  },
}

export const actions = {}

export const getters = {
  getIsShow: (state) => state.isShow,
}
