
export default {
  name: 'SelectComponent',
  props: {
    defaultVal: {
      type: String,
      default: '',
    },
    slotKey: {
      type: String,
      default: '',
    },
    slotValue: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    val: {
      type: [String, Number],
      default: '',
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'medium',
    },
    border: {
      type: String,
      default: 'full-border',
    },
    borderRadius: {
      type: String,
      default: '',
    },
    selectedColor: {
      type: String,
      default: '',
    },
    isMargin: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.val || this.defaultVal
      },
      set(val) {
        this.$emit('update:val', val)
      },
    },
  },
}
