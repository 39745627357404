
import { mapGetters } from 'vuex'

export default {
	name: 'MainBanner',
	data() {
		return {
			swiperOptions: {
				centeredSlides: true,
				autoplay: { delay: 3500 },
				loop: false,
				effect: 'fade',
				grabCursor: true,
				navigation: {
					nextEl: '.swiper-button-next_first',
					prevEl: '.swiper-button-prev_first'
				}
			}
		}
	},
	computed: {
		...mapGetters({
			slides: 'banners/getList'
		})
	},
	methods: {
		convertUrl(url) {
			if (url.includes(' ')) {
				return url.split(' ').join('%20')
			}

			return url
		}
	}
}
