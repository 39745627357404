
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			product: {},
			isExistProduct: false,
			list: [],
			favoriteList: []
		}
	},
	computed: {
		...mapGetters({
			wishList: 'wishList/getWishList'
		})
	},
	methods: {
		...mapActions({
			checkProductInList: 'wishList/checkProductInList',
			addProductsToWishList: 'wishList/addToWishList'
		}),
		beforeOpen(data) {
			document.body.style.overflow = 'hidden'
			const { params } = data

			this.product = params.product
			this.checkProductInList(this.product.id).then(res => {
				this.list = res
			})
		},
		beforeClose() {
			document.body.style.overflow = 'visible'
		},
		async addToWishList(listId) {
			const currentList = this.list.find(el => el.id === listId)

			if (currentList.isExistProduct) {
				this.$modal.show('error-wish-list')
			} else {
				try {
					const data = {
						listId,
						productId: this.product.id,
						userId: this.$auth.user.id
					}
					const response = await this.addProductsToWishList(data)

					if (response.status === 200) {
						this.$store.commit('wishList/SET_CHANGE_WISH_LIST', true)
						this.favoriteList =
							JSON.parse(localStorage.getItem('favorite')) || []
						this.favoriteList.push(this.product.id)
						localStorage.setItem('favorite', JSON.stringify(this.favoriteList))

						const foundList = this.wishList.find(
							el => el.name === currentList.name
						)
						this.$modal.show('congratulation-wish-list', {
							listDetail: {
								name: currentList.name,
								items: foundList?.items
							}
						})
					} else {
						this.$toast.error(response.data.err)
					}
				} catch (error) {
					console.log(error)
				} finally {
					setTimeout(() => {
						this.$store.commit('wishList/SET_CHANGE_WISH_LIST', false)
					}, 500)
				}
			}

			this.hide()
		},
		hide() {
			this.$modal.hide('select-wish-list')
		}
	}
}
