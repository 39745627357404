
import InputSearch from '~/components/ui/InputSearch.vue'
import Novelties from '~/components/Header/DropdownsNav/Novelties.vue'
import SidebarNav from '~/components/Header/SidebarNav.vue'

export default {
  components: {
    InputSearch,
    Novelties,
    SidebarNav,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      steps: [],
      currentStep: 'home',
      stepTranslations: {
        new: 'Новинки',
        device: 'Девайси',
        liquid: 'Рідини',
        taste: 'Смаки',
        accessories: 'Комплектуючі',
        capacity: 'Об’єм',
      },
      subMenus: {
        device: {
          type: 'device',
          bannerOptions: {
            buttonText: 'Бестселлери',
            brushText: 'Популярні',
            mainText: 'Електронні сигарети',
            dropImg: 'device',
            path: {
              name: 'product-type',
              params: { type: 'device' },
              query: { bestseller: true },
            },
          },
        },
        liquid: {
          type: 'liquid',
          bannerOptions: {
            buttonText: 'ТОП Рідин',
            brushText: 'Ми радимо спробувати',
            mainText: '',
            dropImg: 'liquid',
            path: {
              name: 'product-type',
              params: { type: 'liquid' },
              query: { bestseller: true },
            },
          },
        },
        accessories: {
          type: 'accessories',
        },
      },
      navMenu: [
        { title: 'Новинки', step: 'new', subMenu: 'Novelties' },
        { title: 'Девайси', step: 'device', subMenu: 'Device' },
        { title: 'Рідини', step: 'liquid', subMenu: 'Liquid' },
        { title: 'Комплектуючі', step: 'accessories', subMenu: 'Accessories' },
      ],
    }
  },
  computed: {
    sidebarStatus: {
      get() {
        return this.isShow
      },
      set(val) {
        this.$emit('update:isShowSidebar', val)
      },
    },
  },
  watch: {
    '$route.path'(val) {
      if (val) {
        this.$emit('update:isShowSidebar', false)
        this.setSteps('home')
      }
    },
  },
  methods: {
    setSteps(step) {
      this.currentStep = step
    },
    setStep(data) {
      this.currentStep = data.step
    },
    showSearchModal() {
      this.$modal.show('search_modal')
    },
    backStep() {
      this.currentStep = 'home'
    },
  },
}
