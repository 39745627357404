const resource = '/user'

export default ($axios) => ({
  async saveAddress({ userId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/${userId}/address`,
      data,
    })
  },
  async removeAddress(data) {
    return await $axios({
      method: 'DELETE',
      url: `${resource}/${data.userId}/address/${data.id}`,
    })
  },
})
