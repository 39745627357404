
import InputSearch from '~/components/ui/InputSearch.vue'
import AllModals from '~/components/Modals/AllModals.vue'

export default {
	name: 'ErrLayout',

	components: { InputSearch, AllModals },
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	},
	mounted() {
		const checkAge = this.$cookies.get('isCheckedAge')
		if (!checkAge) {
			this.$modal.show('checkAge')
		}

		this.$store.commit('error/SET_ERROR', this.error)
	},
	methods: {
		showSearchModal() {
			this.$modal.show('search_modal')
		}
	}
}
