
import {mapGetters} from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
  name: 'BreadcrumbComponent',
  components: {Button},
  data() {
    return {
      translateRoutes: {
        product: 'Товари',
        device: 'Пристрої',
        liquid: 'Рідини',
        accessories: 'Комплектуючі',
        new: 'Новинки',
      },
    }
  },
  computed: {
    ...mapGetters({
      productDetail: 'product/getProductDetail',
      sectionIds: 'section/getSectionIds',
    }),
    generateRoutes() {
      const reverseSectionIds = Object.fromEntries(
        Object.entries(this.sectionIds).map((el) => el.reverse())
      )

      return [
        {route: '/product', title: 'product'},
        {
          route: `/product/${reverseSectionIds[this.productDetail.section_id]}`,
          title: reverseSectionIds[this.productDetail.section_id],
        },
        {route: this.$route.path, title: this.productDetail.name},
      ]
    },
  },
  methods: {
    async goBack() {
      return await this.$router.push(
        this.generateRoutes[this.generateRoutes.length - 2].route
      )
    },
  },
}
