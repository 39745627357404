
import Loading from '~/components/Loading.vue'
import Input from '~/components/ui/Input.vue'

export default {
	name: 'DropdownComponent',
	components: { Loading, Input },
	props: {
		defaultVal: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		val: {
			type: String,
			default: ''
		},
		optionList: {
			type: Array,
			default: () => []
		},
		slotKeys: {
			type: [Array, Object],
			default: () => {}
		},
		size: {
			type: String,
			default: 'medium'
		},
		clickToClose: {
			type: Boolean,
			default: true
		},
		showSearchBlock: {
			type: Boolean,
			default: false
		},
		fieldSearch: {
			type: String,
			default: ''
		},
		searchVal: {
			type: String,
			default: ''
		},
		paginationParams: {
			type: Object,
			default: () => {}
		},
		isIntersection: {
			type: Boolean,
			default: false
		},
		asyncSearch: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			loading: false,
			isActive: false,
			selectedItem: '',
			dropdownValue: this.val || this.defaultVal
		}
	},
	computed: {
		inputVal: {
			get() {
				return this.searchVal
			},
			set(val) {
				this.$emit('update:searchVal', val)
			}
		},
		getOptionList() {
			if (!this.asyncSearch) {
				if (!this.searchVal) {
					return [...(this.optionList || [])]
				} else {
					return [...(this.optionList || [])].filter(option =>
						option[this.fieldSearch]
							.toLowerCase()
							.includes(this.searchVal.toLowerCase())
					)
				}
			} else {
				return [...(this.optionList || [])]
			}
		}
	},
	// watch: {
	//   optionList(val) {
	//     // if (this.isIntersection) {
	//     //   val.length >= this.paginationParams.limit && this.scrollTrigger()
	//     // }
	//   },
	// },
	updated() {
		if (this.isIntersection && this.isActive) {
			this.optionList.length >= this.paginationParams.limit &&
				this.scrollTrigger()
		}
	},
	methods: {
		closeDropdown() {
			if (this.clickToClose) {
				this.isActive = false
			}
		},
		scrollTrigger() {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.intersectionRatio) {
						const params = {
							currentPage: this.paginationParams?.currentPage,
							totalPage: this.paginationParams?.totalPage,
							limit: this.paginationParams.limit
						}

						if (+params?.currentPage < params?.totalPage) {
							this.$emit('resendRequest', {
								limit: params.limit,
								page: ++params.currentPage
							})
						}
					}
				})
			})

			observer.observe(this.$refs.watchElement)
		},
		updateModel(val, title) {
			if (this.dropdownValue === val) {
				this.dropdownValue = ''
				this.selectedItem = ''
			} else {
				this.selectedItem = title
				this.dropdownValue = val
			}

			this.isActive = false

			this.$emit('update:val', this.dropdownValue)
		},
		changeOption(title, data) {
			this.selectedItem = this.selectedItem !== title ? title : ''

			this.$emit('onChange', data)
			this.isActive = false
		}
	}
}
