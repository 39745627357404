
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
  components: { Button },
  data() {
    return {
      product: {},
    }
  },
  computed: {
    ...mapGetters({
      getCountProduct: 'cart/getProductCount',
      cartList: 'cart/getCartList',
    }),
    getFullPrice() {
      let sum = 0
      const list = [...(this.cartList || [])]

      list.forEach((el) => {
        sum += el.price.amount * el.count
      })

      return sum
    },
    deliveryPrice() {
      return {
        price: 8,
        currency: this.product?.currency?.name,
        isFree: this.cartList?.length > 5,
      }
    },
  },
  methods: {
    beforeOpen(data) {
      document.body.style.overflow = 'hidden'

      const { params } = data

      this.product = params.product
    },
    beforeClose() {
      document.body.style.overflow = 'visible'
    },
    async goToCart() {
      this.hide()
      await this.$router.push('/shop-cart')
    },
    async goToProductPage() {
      this.hide()
      await this.$router.push('/product/liquid')
    },
    hide() {
      this.$modal.hide('success-added-to-cart')
    },
  },
}
