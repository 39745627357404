
import { mapActions, mapGetters } from 'vuex'

import RoundIcon from '~/components/ui/RoundIcon.vue'
import Button from '~/components/ui/Button.vue'
import Cart from '~/components/Header/ShortCart.vue'
import Device from '~/components/Header/DropdownsNav/Device.vue'
import Liquid from '~/components/Header/DropdownsNav/Liquid.vue'
import Accessories from '~/components/Header/DropdownsNav/Accessories.vue'
import TopNav from '~/components/Header/TopNav.vue'
import InputSearch from '~/components/ui/InputSearch.vue'
import Sidebar from '~/components/Header/Sidebar.vue'
import Novelties from '~/components/Header/DropdownsNav/Novelties.vue'
import Tooltip from '~/components/ui/Tooltip.vue'

export default {
  components: {
    Cart,
    Device,
    Liquid,
    Accessories,
    TopNav,
    InputSearch,
    Sidebar,
    Novelties,
    Tooltip,
    Button,
    RoundIcon,
  },
  data() {
    return {
      DESKTOP_SIZE: 1024,
      show: false,
      shortMenu: false,
      isShowSidebar: false,
      hoverItem: '',
      contactList: [
        { title: 'СМС', icon: 'sms_icon', path: 'https://t.me/hot_vape_stock' },
        { title: 'Телефон', icon: 'phone_icon', path: 'tel:+380934136913' },
        { title: 'E-mail', icon: 'email_icon', path: '/contact' },
      ],
      userList: [
        {
          path: '/profile',
          title: 'Мій профіль',
          icon: 'account',
          className: ['is_logged'],
        },
        { path: '/orders', title: 'Список замовлень', icon: 'order_list' },
        { path: '/favorites', title: 'Список бажаного', icon: 'wish_list' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/getInfo',
      sectionIds: 'section/getSectionIds',
      isShowSearch: 'config/getIsShow',
    }),
    userName() {
      return this.$auth.user.first_name
    },
  },
  watch: {
    isShowSidebar(val) {
      if (val) document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'scroll'
    },
  },
  async created() {
    const bannedRoutes = [
      'product-type',
      'product-new',
      'product-new-section',
      'product-type-category',
      'index',
    ]

    if (!bannedRoutes.includes(this.$route.name))
      await this.$store.dispatch('section/getSections')
  },
  async mounted() {
    await this.getBrandsBySection({ device: this.sectionIds.device })
    await this.getBrandsBySection({ accessories: this.sectionIds.accessories })
    await this.getBrandsBySection({ liquid: this.sectionIds.liquid })

    document.addEventListener('scroll', () => {
      this.shortMenu = window.scrollY > 60
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth > this.DESKTOP_SIZE) {
        this.isShowSidebar = false
      }
    })
  },
  beforeDestroy() {
    document.addEventListener('scroll', () => {
      this.shortMenu = window.scrollY > 60
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth > this.DESKTOP_SIZE) {
        this.isShowSidebar = false
      }
    })
  },
  methods: {
    ...mapActions({
      getBrandsBySection: 'brands/fetchBrandsBySection',
    }),
    async logoutUser() {
      this.$store.commit('wishList/SET_WISH_LIST', [])
      this.$store.commit('cart/SET_CART_LIST', [])
      this.$store.commit('wishList/RESET_IDS')

      await this.$auth.logout()
    },
    showSearchModal() {
      this.$store.commit('config/SET_SHOW_SEARCH', true)
      this.$modal.show('search_modal')
    },
    showSidebar() {
      this.isShowSidebar = !this.isShowSidebar
      this.$store.commit('config/SET_SHOW_SEARCH', false)

      this.$modal.hide('search_modal')
    },
    async goTo(type) {
      return await this.$router.push({
        name: `product-type`,
        params: { type },
      })
    },
    setSearchBtn() {
      this.$store.commit('config/SET_SHOW_SEARCH', true)

      this.showSearchModal()
    },
  },
}
