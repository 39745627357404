
import { mapGetters, mapActions } from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
	components: { Button },
	props: {
		product: {
			type: Object,
			default: () => {}
		},
		type: {
			type: String,
			default: 'product'
		}
	},
	data() {
		return {
			loading: false,
			success: false,
			error: false,
			count: 1,
			models: {},
			dataOptions: {},
			firstSelectedModels: {},
			productItem: {},
			selectedProductByFilter: [],
			translations: {
				nicotine_type: 'Нікотин',
				dosage_nicotine: 'Міцність',
				dosage: "Об'єм",
				taste: 'Смак',
				color: 'Колір',
				resistance: 'Опір'
			}
		}
	},
	computed: {
		...mapGetters({
			getDeletedElementId: 'cart/getDeletedElementId'
		}),
		prodElement() {
			return this.type !== 'search'
				? this.selectedProductByFilter[0]
				: this.productItem
		}
	},
	watch: {
		getDeletedElementId(val) {
			if (val) {
				this.$store.commit('product/UPDATE_QTY', { id: val, qty: 0 })

				this.$store.commit('cart/RESET_DELETED_ID')
			}
		},
		product: {
			deep: true,
			immediate: true,
			handler(val) {
				if (val) {
					this.productItem = { ...val }
				}
			}
		}
	},
	created() {
		this.productItem = { ...this.product }

		if (this.type !== 'search') {
			this.generateOptions(this.productItem.sub_products)
			this.selectedProductByFilter = [{ ...this.productItem.sub_products[0] }]
			this.models = { ...(this.productItem?.sub_products[0]?.options || {}) }
		} else {
			this.generateOptions([this.productItem])

			this.models = this.productItem?.options
		}
	},
	methods: {
		...mapActions({
			addToShopCart: 'cart/addToShopCart'
		}),
		setAvailableCount(data, e) {
			if (+e?.target?.value) {
				this.count = +e.target.value - 1
			}

			if (data.qty) {
				if (+e?.target?.value > data.stock - data.qty) {
					this.$toast.error(
						`До замовлення доступно тільки ${data.stock - data.qty} товарів`
					)

					this.count = data.stock - data.qty
					return
				}

				if (this.count < data.stock - data.qty) {
					++this.count
				}
			} else {
				if (+e?.target?.value > data.stock) {
					this.$toast.error(
						`До замовлення доступно тільки ${data.stock} товарів`
					)
					this.count = data.stock
					return
				}

				this.count < data.stock && ++this.count
			}
		},
		addToCart() {
			this.loading = true
			let isExist = null
			let stockSubProduct = null
			let order = {}

			const orderList = JSON.parse(localStorage.getItem('order_list')) || []

			const existFilters = Object.keys(this.models).reduce((options, key) => {
				if (this.models[key]) return { ...options, [key]: this.models[key] }
				return { ...options }
			}, {})

			const list =
				this.type === 'search'
					? [this.productItem]
					: this.productItem?.sub_products

			this.selectedProductByFilter = [...(list || [])].filter(product =>
				Object.entries(existFilters).every(
					([k, v]) => v && product.options[k] === v
				)
			)

			if (this.type !== 'search') {
				const { id, ...subProduct } = this.selectedProductByFilter[0]
				isExist = orderList.find(product => product.sub_product_id === id)

				stockSubProduct = isExist && isExist.stock

				if (isExist) {
					const foundIndex = orderList.findIndex(
						el => el.sub_product_id === isExist.sub_product_id
					)

					orderList.splice(foundIndex, 1)

					const { qty, ...data } = isExist

					order = {
						...data,
						qty: qty + this.count,
						subIds: this.productItem.subIds
					}
				} else {
					order = {
						...subProduct,
						sub_product_id: id,
						qty: this.count,
						subIds: this.productItem.subIds
					}
				}

				if (stockSubProduct <= this.count || stockSubProduct <= isExist?.qty) {
					this.error = true
					this.$toast.error(`Обрана к-сть товарів відсутня`)
				} else {
					this.$store.commit('product/UPDATE_QTY', {
						id: this.selectedProductByFilter[0].id,
						qty: order.qty
					})

					this.selectedProductByFilter[0].qty = order.qty
					orderList.push(order)
				}
			} else {
				const { id, ...subProduct } = this.selectedProductByFilter[0]
				isExist = orderList.find(product => product.sub_product_id === id)

				stockSubProduct = isExist && isExist.stock

				if (isExist) {
					const foundIndex = orderList.findIndex(
						el => el.sub_product_id === isExist.id
					)

					orderList.splice(foundIndex, 1)

					const { qty, ...data } = isExist

					order = {
						...data,
						qty: qty + this.count,
						subIds: this.productItem.subIds
					}
				} else {
					order = {
						...subProduct,
						sub_product_id: id,
						qty: this.count,
						subIds: this.productItem.subIds
					}
				}

				if (stockSubProduct <= this.count || stockSubProduct <= isExist?.qty) {
					this.error = true
					this.$toast.error(`Обрана к-сть товарів відсутня`)
				} else {
					this.$store.commit('product/UPDATE_QTY', {
						id: this.selectedProductByFilter[0].id,
						qty: order.qty
					})

					this.selectedProductByFilter[0].qty = order.qty
					orderList.push(order)
				}
			}

			localStorage.setItem('order_list', JSON.stringify(orderList))

			this.$store.commit('cart/SET_CART_LIST')

			this.success = true

			this.count = 1
			setTimeout(() => {
				this.error = false
				this.success = false
				this.loading = false
			}, 1000)
		},
		onChangeModel() {
			this.selectedProductByFilter = [
				...(this.productItem?.sub_products || [])
			].filter(product =>
				Object.entries(this.models).every(
					([k, v]) => v && product.options[k] === v
				)
			)

			if (
				(Object.keys(this.models).length &&
					Object.prototype.hasOwnProperty.call(this.models, 'taste')) ||
				Object.prototype.hasOwnProperty.call(this.models, 'color') ||
				Object.prototype.hasOwnProperty.call(this.models, 'resistance')
			) {
				this.$emit('setItems', this.selectedProductByFilter)
			} else {
				this.$emit('setItems', [])
			}
		},
		generateOptions(data) {
			this.dataOptions = {}

			const list = [...(data || [])]
			const optionKeys = Object.keys(this.productItem?.options)

			optionKeys.forEach(key => {
				this.dataOptions[key] = [
					...new Set(
						list.reduce((acc, el) => {
							return [...acc, el?.options[key]?.trim()]
						}, [])
					)
				].sort((a, b) => a - b)
			})

			return this.dataOptions
		}
	}
}
