
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
  name: 'CookiesComponent',
  components: { Button },
  computed: {
    ...mapGetters({
      isPWA: 'checkPWA/getOpenedAsPWA',
    }),
  },
  created() {
    const cookie = this.$cookies.get('aboutCookies')

    if (cookie === undefined) {
      !cookie && this.$store.commit('checkPWA/OPENED_AS_PWA', false)
    } else {
      this.$store.commit('checkPWA/OPENED_AS_PWA', true)
    }
  },
  beforeMount() {
    if (this.$device.isMobileOrTablet) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.$store.commit('checkPWA/OPENED_AS_PWA', true)
      }
    }
  },
  methods: {
    setNextAskCookies() {
      const current = new Date().getTime()
      const yearInMSecond = Number('3.1536e+10')
      const deathDate = new Date(current + yearInMSecond)

      this.$cookies.set('aboutCookies', true, {
        expires: deathDate,
        path: '/',
        secure: false,
        sameSite: 'lax',
      })
    },
    showMore() {
      this.$modal.show('infoCookies')
    },
    acceptTerms() {
      this.setNextAskCookies()
      this.$store.commit('checkPWA/OPENED_AS_PWA', true)
    },
  },
}
