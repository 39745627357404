import { render, staticRenderFns } from "./Liquid.vue?vue&type=template&id=d0ff7da4&scoped=true&"
import script from "./Liquid.vue?vue&type=script&lang=js&"
export * from "./Liquid.vue?vue&type=script&lang=js&"
import style0 from "./Liquid.vue?vue&type=style&index=0&id=d0ff7da4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0ff7da4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/var/www/vape_shop_nuxt_2/components/Banner.vue').default})
