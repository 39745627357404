
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'

export default {
  name: 'PaginationButton',
  components: { Button },
  props: {
    currentPage: { type: Number, default: 1 },
    pages: { type: Number, default: 1 },
    size: { type: Number, default: 8 },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      productList: 'product/getProductList',
    }),
    nextIsPossible() {
      return this.currentPage < this.pages
    },
  },
  mounted() {},
  methods: {
    nextPage() {
      let currentPage = this.currentPage
      const params = {}

      if (this.nextIsPossible) {
        params.pagination = {
          page: ++currentPage,
          size: this.size,
        }
      }

      this.$emit('onChangePagination', params)
    },
  },
}
