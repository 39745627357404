export const state = () => ({
	departments: [],
	info: {},
	departmentTypes: [],
	cityList: [],
	streets: []
})

export const mutations = {
	SET_DEPARTMENTS(state, data) {
		state.departments = data
	},
	SET_DOCUMENT_INFO(state, data) {
		state.info = data
	},
	SET_DEPARTMENT_TYPES(state, data) {
		state.departmentTypes = data.data
	},
	SET_CITY_LIST(state, data) {
		state.cityList = [...(data?.data[0]?.Addresses || [])]
	},
	SET_STREETS(state, data) {
		state.streets = [...(data?.data[0]?.Addresses || [])]
	},
	CLEAR_STREETS(state) {
		state.streets = []
	},
	CLEAR_DEPARTMENTS(state) {
		state.departments = []
	},
	CLEAR_CITY_LIST(state) {
		state.cityList = []
	}
}

export const actions = {
	async getDepartmentsByName({ commit }, data) {
		const response = await this.$repositories.novaPoshta.getDepartments(data)
		if (!response.data.success) {
			response.data.errors.forEach(error => this.$toast.error(error))

			return
		}

		commit('SET_DEPARTMENTS', response.data.data)

		return response
	},
	async getDocumentPrice({ commit }, data) {
		const response = await this.$repositories.novaPoshta.getDocumentPrice(data)
		if (!response.data.success) {
			response.data.errors.forEach(error => this.$toast.error(error))

			return
		}

		commit('SET_DOCUMENT_INFO', response.data.data)

		return response
	},
	async getWarehouseTypes({ commit }, data) {
		const response = await this.$repositories.novaPoshta.getWarehouseTypes(data)
		if (!response.data.success) {
			response.data.errors.forEach(error => this.$toast.error(error))

			return
		}

		commit('SET_DEPARTMENT_TYPES', response.data)

		return response
	},
	async getCityList({ commit }, data) {
		const response = await this.$repositories.novaPoshta.getCityList(data)
		if (!response.data.success) {
			response.data.errors.forEach(error => this.$toast.error(error))

			return
		}

		commit('SET_CITY_LIST', response.data)

		return response
	},
	async searchSettlementStreets({ commit }, data) {
		const response =
			await this.$repositories.novaPoshta.searchSettlementStreets(data)

		if (!response.data.success) {
			response.data.errors.forEach(error => this.$toast.error(error))

			return
		}

		commit('SET_STREETS', response.data)

		return response
	}
}

export const getters = {
	getDepartments: state => state.departments || [],
	getCityList: state => state.cityList || [],
	getStreets: state => state.streets || [],
	getDepartmentTypes: state => {
		return [...(state.departmentTypes || [])].reduce((acc, item) => {
			if (['Поштове відділення', 'Поштомат'].includes(item.Description))
				return { ...acc, [item.Description]: item.Ref }

			return { ...acc }
		}, {})
	}
}
