import { ObjectError } from '~/utils/error'

export const state = () => ({
	list: [],
	isChange: false,
	idList: []
})

export const mutations = {
	SET_WISH_LIST(state, data) {
		state.list = data
	},
	SET_CHANGE_WISH_LIST(state, data) {
		state.isChange = data
	},
	SET_IDS(state, data) {
		const favoriteList =
			(process.client && JSON.parse(localStorage.getItem('favorite'))) || []
		const list = [...(data || [])]
			.reduce((acc, el) => [...acc, ...el.items], [])
			.reduce((ids, item) => [...ids, item.product.id], [])

		if (!favoriteList.length) {
			process.client && localStorage.setItem('favorite', JSON.stringify(list))
		}

		state.idList = list
	},
	RESET_IDS(state) {
		localStorage.setItem('favorite', JSON.stringify([]))
		state.idList = []
	}
}

export const actions = {
	async getAllUserList({ commit }, data) {
		try {
			const response = await this.$repositories.wishList.getUserWishlists(data)
			if (response.status !== 200) throw new ObjectError(response)

			commit('SET_IDS', response.data)
			commit('SET_WISH_LIST', response.data)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async addToWishList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.addProductToList(data)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async createWishList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.createWishList(data)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async clearWishList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.clearList(data)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async removeList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.removeList(data)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async removeProductFromList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.removeProductFromList(
				data
			)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	async renameWishList({ commit, dispatch }, data) {
		try {
			const response = await this.$repositories.wishList.renameList(data)
			if (response.status !== 200) throw new ObjectError(response)

			const resp = await dispatch('getAllUserList', data)
			if (resp.status !== 200) throw new ObjectError(resp)

			return response
		} catch (error) {
			const typedError = error

			return typedError.data
		}
	},
	checkProductInList({ commit, state }, id) {
		return state.list.reduce((acc, el) => {
			const isExistProduct = el.items.find(product => product.product.id === id)

			if (isExistProduct) el.isExistProduct = true
			else el.isExistProduct = false
			return [...acc, el]
		}, [])
	}
}

export const getters = {
	getWishList: state => state.list,
	isChange: state => state.isChange,
	getIds: state => state.idList
}
