
import AllModals from '~/components/Modals/AllModals.vue'
import Cookies from '~/components/Cookies.vue'

export default {
	name: 'AuthLayout',
	components: { AllModals, Cookies },
	mounted() {
		const checkAge = this.$cookies.get('isCheckedAge')

		if (!checkAge) {
			this.$modal.show('checkAge')
		}
	}
}
