
import Button from '~/components/ui/Button.vue'

export default {
	components: { Button },
	data() {
		return {
			list: {}
		}
	},
	methods: {
		beforeOpen(data) {
			document.body.style.overflow = 'hidden'

			const { params } = data

			this.list = params.listDetail
		},
		beforeClose() {
			document.body.style.overflow = 'visible'
		},
		hide() {
			this.$modal.hide('congratulation-wish-list')
		},
		seeWishList() {
			this.$modal.show('wish-list', {
				listDetail: { items: this.list.items, wishItem: this.list }
			})
			this.hide()
		}
	}
}
