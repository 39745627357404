
export default {
  name: 'RadioButtonComponent',
  props: {
    val: {
      type: [Number, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'form_radio_id',
    },
    name: {
      type: String,
      default: 'form_radio_name',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.val
      },
      set(val) {
        this.$emit('update:val', val)
      },
    },
  },
}
