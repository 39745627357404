
import { mapGetters } from 'vuex'

import InputSearch from '~/components/ui/InputSearch.vue'
import Item from '~/components/Products/Item.vue'
import Dropdown from '~/components/ui/Dropdown.vue'
import ButtonPagination from '~/components/ButtonPagination.vue'
import Loading from '~/components/Loading.vue'

export default {
	components: { InputSearch, Item, Dropdown, ButtonPagination, Loading },
	data() {
		return {
			searchValue: '',
			filterBy: '',
			sortBy: '',
			loading: false,
			timeoutID: null,
			totalRecords: 0,
			error: false,
			serverParams: {
				page: 1,
				size: 8
			},
			optionsDropdown: {
				filter: [
					{ title: 'Девайси', value: 'device' },
					{ title: 'Рідини', value: 'liquid' },
					{ title: 'Комплекуючі', value: 'accessories' }
				],
				sortBy: [
					{ title: 'Від дешевих до дорогих', value: 'ASC' },
					{ title: 'Від дорогих до дешевих', value: 'DESC' }
				]
			}
		}
	},
	computed: {
		...mapGetters({
			productsList: 'product/getProductList',
			searchList: 'product/getSearchList',
			sectionIds: 'section/getSectionIds'
		}),
		totalPages() {
			return Math.ceil(this.totalRecords / this.serverParams.size)
		}
	},
	watch: {
		$route(val) {
			if (val) {
				this.hide()
			}
		}
	},
	methods: {
		async beforeOpen() {
			document.body.style.overflow = 'hidden'

			this.$store.commit('product/RESET_PRODUCT_LIST')
			await this.loadItems()
		},
		async beforeClose() {
			document.body.style.overflow = 'visible'
			this.$store.commit('product/RESET_SEARCH_LIST')
			await this.$store.dispatch('product/fetchNewBySection', {
				reset: true,
				sections: Object.values(this.sectionIds),
				pagination: {
					page: 1,
					size: 8
				}
			})
		},
		async setFilterBy(data) {
			this.filterBy = data

			if (!this.searchValue) this.$store.commit('product/RESET_PRODUCT_LIST')
			this.resetPagination()
			await this.loadItems(true)
		},
		async setSortBy(data) {
			this.sortBy = data

			if (!this.searchValue) this.$store.commit('product/RESET_PRODUCT_LIST')
			this.resetPagination()
			await this.loadItems('sortBy')
		},
		resetPagination() {
			this.serverParams = { page: 1, size: 8 }
		},
		async setSearch(data) {
			this.error = false
			this.searchValue = data

			if (data.length >= 3) {
				if (this.timeoutID) clearTimeout(this.timeoutID)
				this.timeoutID = setTimeout(async () => {
					this.searchValue = data

					this.resetPagination()
					this.$store.commit('product/RESET_SEARCH_LIST')
					await this.loadItems()
				}, 700)
			} else if (!data.length) {
				this.resetPagination()
				this.$store.commit('product/RESET_SEARCH_LIST')
				await this.loadItems()
			} else this.error = true
		},
		async loadItems(type) {
			try {
				this.loading = true

				const params = {
					reset: type && true,
					search_value: this.searchValue,
					sort: this.sortBy ? `total_price ${this.sortBy}` : 'total_price',
					section_id: this.filterBy
						? this.sectionIds[this.filterBy]
						: this.filterBy,
					pagination: this.serverParams
				}

				const response = await this.$store.dispatch('product/setSearch', params)

				if (response.status === 200) {
					this.totalRecords = response.data.total_count
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		resetData() {
			this.resetPagination()
			this.searchValue = ''
			this.sortBy = ''
			this.filterBy = ''
		},
		hide() {
			this.$store.commit('config/SET_SHOW_SEARCH', false)
			this.resetData()
			this.$modal.hide('search_modal')
		}
	}
}
