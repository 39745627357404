
import RoundIcon from '~/components/ui/RoundIcon.vue'

export default {
  name: 'TooltipComponent',
  components: { RoundIcon },
  props: {
    tooltipOption: {
      type: Object,
      default: () => ({
        isIconTitle: true,
        icon: 'example',
        className: 'wrapper',
      }),
    },
    title: {
      type: String,
      default: '',
    },
    contentList: {
      type: Array,
      default: () => [],
    },
    arrowPosition: {
      type: String,
      default: 'center',
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
}
