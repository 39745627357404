
export default {
  name: 'InputSearchComponent',
  props: {
    val: {
      type: String,
      default: '',
    },
    reference: {
      type: null,
      default: null,
    },
    id: {
      type: String,
      default: 'input_search_id',
    },
  },
  computed: {
    searchValue: {
      get() {
        return this.val
      },
      set(val) {
        this.$emit('update:val', val)
      },
    },
  },
}
