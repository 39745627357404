
import { mapGetters } from 'vuex'

import Overlay from '~/components/Products/OverlayProduct.vue'

export default {
	components: { Overlay },
	props: {
		id: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'product'
		},
		product: {
			type: Object,
			default: () => {}
		},
		dataNotLazy: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			labels: {
				bestseller: 'Бестселлер',
				new: 'Новинка',
				low_quantity: 'Розпродаж',
				isPopular: 'Популярні'
			},
			translations: {
				color: 'Колір',
				organic: 'Органічний',
				salt: 'Сольовий',
				cherry: 'Ягода',
				apple: 'Яблуко',
				tabacco: 'Табак',
				mint: 'М`ята',
				fruit: 'Фрукти',
				drink: 'Напої',
				nicotine_type: 'Нікотин',
				dosage_nicotine: 'Міцність',
				dosage: "Об'єм",
				taste: 'Смак',
				resistance: 'Опір'
			},
			isActive: false,
			selectedItems: []
		}
	},
	computed: {
		...mapGetters({
			productInWishList: 'wishList/getIds',
			searchList: 'product/getSearchList'
		}),
		getImage() {
			if (this.selectedItems.length) {
				return [this.convertUrl(this.selectedItems[0].image_url)]
			} else {
				return this.type === 'search' && this.searchList.length
					? this.convertUrl(this.product?.image_url)
					: this.convertUrl(this.product?.image_urls[0])
			}
		}
	},
	watch: {
		productInWishList(newVal, oldVal) {
			if (newVal) {
				this.inFavorite()
			}
		}
	},
	mounted() {
		this.inFavorite()
	},
	methods: {
		convertUrl(url) {
			if (url.includes(' ')) {
				return url.split(' ').join('%20')
			}

			return url
		},
		inFavorite() {
			const favoriteList = JSON.parse(localStorage.getItem('favorite')) || []
			this.isActive = favoriteList.includes(this.product.id)
		},
		setItems(data) {
			this.selectedItems = data
		},
		setAltImg(event) {
			event.target.src = '/images/img_not_found.png'
		}
	}
}
