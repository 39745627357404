export const state = () => ({
  error: {},
})

export const mutations = {
  SET_ERROR(state, data) {
    state.error = data
  },
}

export const getters = {
  getError: (state) => state.error,
}
