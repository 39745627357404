
import { mapGetters } from 'vuex'

import RadioButton from '~/components/ui/RadioButton.vue'
import Button from '~/components/ui/Button.vue'

export default {
  components: { RadioButton, Button },
  data() {
    return {
      type: 'new',
    }
  },
  computed: {
    ...mapGetters({
      getAddress: 'address/getAddress',
      addressList: 'address/getAddressList',
    }),
    addresses() {
      return this.$auth.user.addresses
    },
    selectedAddress: {
      get() {
        return this.$auth.user.selectedAddress
      },
      async set(val) {
        const resp = await this.$store.dispatch('user/updateUserInfo', {
          id: this.getUser.id,
          selectedAddress: val,
        })

        if (resp.status === 200) {
          this.$auth.setUser(resp.data)
          await this.$store.dispatch('address/getList')
        }
      },
    },
    getUser() {
      return this.$auth.user
    },
  },
  methods: {
    beforeOpen() {
      document.body.style.overflow = 'hidden'
    },
    beforeClose() {
      document.body.style.overflow = 'visible'
    },
    showForm(data) {
      this.$modal.show('address-change-modal', { data })
      this.hide()
    },
    hide() {
      this.$modal.hide('address-modal')
    },
  },
}
