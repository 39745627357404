const resource = '/shop'

export default ($axios) => ({
  async getRandomReviews() {
    return await $axios({
      method: 'GET',
      url: `${resource}/reviews/random`,
    })
  },
  async getProductReview(data) {
    return await $axios({
      method: 'GET',
      url: `${resource}/product/${data.productId}/reviews`,
    })
  },
  async createReview({ productId, ...data }) {
    return await $axios({
      method: 'PUT',
      url: `${resource}/product/${productId}/review`,
      data,
    })
  },
})
