const resource = '/user'

export default ($axios) => ({
  async login(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/login`,
      data,
    })
  },
  async registration(data) {
    return await $axios({
      method: 'POST',
      url: `${resource}/register`,
      data,
    })
  },
  async logout() {
    return await $axios({
      method: 'DELETE',
      url: `/session`,
    })
  },
  async refresh() {
    return await $axios({
      method: 'PUT',
      url: `/session`,
    })
  },
})
