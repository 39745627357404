import { render, staticRenderFns } from "./account.vue?vue&type=template&id=65056cf0&scoped=true&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"
import style0 from "./account.vue?vue&type=style&index=0&id=65056cf0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65056cf0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/www/vape_shop_nuxt_2/components/Header/Header.vue').default,ModalsCheckAge: require('/var/www/vape_shop_nuxt_2/components/Modals/CheckAge.vue').default,AccountAside: require('/var/www/vape_shop_nuxt_2/components/Account/AccountAside.vue').default,Cookies: require('/var/www/vape_shop_nuxt_2/components/Cookies.vue').default,Footer: require('/var/www/vape_shop_nuxt_2/components/Footer/Footer.vue').default})
