
import AllModals from '~/components/Modals/AllModals.vue'
import Cookies from '~/components/Cookies.vue'

export default {
	name: 'OrderLayout',
	components: { AllModals, Cookies },
	data() {
		return {
			shortMenu: false
		}
	},
	mounted() {
		const checkAge = this.$cookies.get('isCheckedAge')
		if (!checkAge) {
			this.$modal.show('checkAge')
		}

		document.addEventListener('scroll', () => {
			this.shortMenu = window.scrollY > 60
		})
	},
	beforeDestroy() {
		document.addEventListener('scroll', () => {
			this.shortMenu = window.scrollY > 60
		})
	}
}
