
import Breadcrumb from '~/components/ui/Breadcrumb.vue'
import ProductBreadcrumb from '~/components/ui/ProductBreadcrumb.vue'
import Footer from '~/components/Footer/Footer.vue'
import AllModals from '~/components/Modals/AllModals.vue'
import Cookies from '~/components/Cookies.vue'

export default {
	name: 'DefLayout',
	components: { Breadcrumb, Footer, AllModals, Cookies, ProductBreadcrumb },
	data: () => ({
		bannedRoute: ['index', 'shop-cart']
	}),
	async fetch() {
		if (this.$auth.loggedIn)
			await this.$store.dispatch('wishList/getAllUserList', {
				userId: this.$auth.user.id
			})
	},
	mounted() {
		const checkAge = this.$cookies.get('isCheckedAge')

		if (!checkAge) {
			this.$modal.show('checkAge')
		}
	}
}
