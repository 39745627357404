import { ObjectError } from '~/utils/error'

export const state = () => ({
  list: [],
  deletedId: '',
})

export const mutations = {
  DELETE_PRODUCT_CART(state, data) {
    state.list = state.list.filter(
      (item) => item.sub_product_id !== data.sub_product_id
    )
    state.deletedId = data.sub_product_id

    localStorage.setItem('order_list', JSON.stringify(state.list))
  },
  ADD_PRODUCT_CART(state, data) {
    state.list.push(...data)
  },
  SET_CART_LIST(state, data) {
    state.list = JSON.parse(localStorage.getItem('order_list')) || []
  },
  RESET_DELETED_ID(state) {
    state.deletedId = ''
  },
}

export const actions = {
  async getCart({ commit }) {
    try {
      const response = await this.$repositories.cart.getCart()
      if (response.status !== 200) throw new ObjectError(response)

      commit('SET_CART_LIST', response.data.data)

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
  async addToShopCart({ commit, dispatch }, data) {
    try {
      const response = await this.$repositories.cart.addToCart(data)
      if (response.status !== 200) throw new ObjectError(response)

      const res = await dispatch('getCart')
      if (response.status !== 200) throw new ObjectError(res)

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
  async removeProduct({ commit, dispatch }, data) {
    try {
      const response = await this.$repositories.cart.removeFromCart(data)
      if (response.status !== 200) throw new ObjectError(response)

      const res = await dispatch('getCart')
      if (response.status !== 200) throw new ObjectError(res)

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
  async createOrder({ commit }, data) {
    try {
      const response = await this.$repositories.cart.createOrder(data)
      if (response.status !== 200) throw new ObjectError(response)

      return response
    } catch (error) {
      const typeError = error

      return typeError.data
    }
  },
}

export const getters = {
  getDeletedElementId: (state) => state.deletedId || '',
  getCartList: (state) => state.list,
  getProductCount: (state) => state.list?.length || 0,
}
